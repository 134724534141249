import { isJSON } from '../functions/functions.utils';

export interface ErrorObject {
  code: string;
  message: string;
  type?: 'axios' | 'server-response' | 'firebase' | 'unknown';
}

export function isCodeMessageObject(error: any): error is ErrorObject {
  return (
    isJSON(error) && error?.hasOwnProperty('code') && error?.hasOwnProperty('message')
  );
}
