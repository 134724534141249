import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AxiosError } from 'axios';

import { getFirebaseError } from './firebase.error';
import { translate } from '../lang';
import { getAxiosUserError } from './axios.error';
import { ErrorObject, isCodeMessageObject } from './error.utils';
import { isJSON } from '../functions/functions.utils';

export type ErrorResponse =
  | FetchBaseQueryError
  | SerializedError
  | ErrorObject
  | { error: unknown };

export const UNKNOWN_CODE = '-100';

/**
 * Checks if the given data is a valid (non-error) response.
 */
export function isOkResponse<T>(data: { error: unknown } | T): data is T {
  return !isErrorResponse(data);
}

/**
 * Validates the response by checking if the error code equals UNKNOWN_CODE.
 */
export const isResponseValid = <T>(data: T | ErrorResponse): data is T =>
  getErrorResponse(data).code === UNKNOWN_CODE;

/**
 * Determines whether the data represents an error response.
 * Here we use a success code of '-110' for the check.
 */
export const isErrorResponse = (data: any): data is ErrorResponse =>
  getErrorResponse(data, undefined, '-110').code !== '-110';

/**
 * Checks if the error is a RTK Query error.
 * Ensures that error is a JSON object with a numeric status and an object data.
 */
const isFetchBaseQuery = (error: any): error is FetchBaseQueryError => {
  return (
    isJSON(error) &&
    typeof error.status === 'number' &&
    typeof error.data === 'object' &&
    error.data !== null
  );
};

/**
 * Checks if the error is an Axios error.
 * This implementation uses the Axios convention of the isAxiosError flag.
 */
function isAxiosFetchError(error: any): error is AxiosError {
  return (
    !!error &&
    typeof error === 'object' &&
    'isAxiosError' in error &&
    error.isAxiosError === true
  );
}

/**
 * Checks if the error object contains a nested error (e.g. { error: ... }).
 */
const hasNestedErrorObj = (error: any): error is { error: any } => {
  return isJSON(error) && 'error' in error && !!error.error;
};

/**
 * Returns a standardized error object based on various error types.
 */
export const getErrorResponse = (
  error: any,
  fallbackMessage?: string,
  isSuccessCode?: string,
  type?: ErrorObject['type'],
): ErrorObject => {
  const defaultMessage =
    fallbackMessage || translate('app.feedback.error.unexpectedError');

  // If error is falsy or not a JSON object, return a generic error.
  if (!error || !isJSON(error)) {
    return {
      message: defaultMessage,
      code: isSuccessCode || UNKNOWN_CODE,
      type: 'unknown',
    };
  }

  // Handle Axios errors.
  if (isAxiosFetchError(error)) {
    return getAxiosUserError(error);
  }

  // Handle RTK Query errors.
  if (isFetchBaseQuery(error)) {
    return getErrorResponse(error.data, defaultMessage, isSuccessCode, type);
  }

  // If the error is a code/message object (e.g. from Firebase), normalize it.
  if (isCodeMessageObject(error)) {
    const codeMessageError = getFirebaseError(error);
    return {
      ...codeMessageError,
      type: codeMessageError.type ?? type ?? 'server-response',
    };
  }

  // If error is a standard JavaScript Error, extract its message.
  if (error instanceof Error) {
    return {
      message: error.message || defaultMessage,
      code: isSuccessCode || UNKNOWN_CODE,
      type: type ?? 'unknown',
    };
  }

  // Unwrap nested error objects.
  if (hasNestedErrorObj(error)) {
    return getErrorResponse(error.error, defaultMessage, isSuccessCode, type);
  }

  // Fallback: return the default error response.
  return {
    message: defaultMessage,
    code: isSuccessCode || UNKNOWN_CODE,
    type: type ?? 'unknown',
  };
};

/**
 * Handles a response by invoking the success callback if no error is detected,
 * otherwise the error callback is called.
 * A default error handler is provided to log unhandled errors.
 */
export const handleResponse = <T>(
  data: T | ErrorResponse,
  onSuccess: (_: T) => void | Promise<void>,
  onError: (_: ErrorObject) => void | Promise<void> = (err) => {
    console.error('Unhandled error:', err);
  },
) => {
  if (isErrorResponse(data)) {
    onError(getErrorResponse(data));
    return;
  }
  onSuccess(data);
};

export * from './error.utils';
