import {
    Optional,
    PriceClientVerifyAttention,
    PriceClientVerifyRoutePriceResponseV2,
    RoutePriceV2,
    RouteTrip,
} from '@naus-code/naus-client-types';
import { ErrorObject, cloneDeep, generateRandomString, isCodeMessageObject } from '../utils/functions';
import { BasketManagerTariffsDispatch } from './bm.3.vTariff.0.dispatch';
import { ClientBasketState } from '../types/clientState';
import { InitiateCallbackProps, InitiateStateProps } from './bm.1.setup';
import { createError } from '../errors';

export interface ResumeStateProps extends Omit<InitiateStateProps, 'query'> {
    routePrice: RoutePriceV2;
    verifyAttention?: PriceClientVerifyAttention;
    disablePriceReset?: boolean;
    disableErrorReset?: boolean;
}

export interface UpdateStateProps extends Optional<InitiateCallbackProps> {
    routePrice: RoutePriceV2;
    selectedTrips?: RouteTrip[];
    verifyAttention?: PriceClientVerifyAttention;
    disableLowAvailability?: boolean;
    disablePriceReset?: boolean;
    disableErrorReset?: boolean;
}

export class BasketManagerPrice extends BasketManagerTariffsDispatch {
    //---------------RESETS---------------//

    private verifyAttention?: PriceClientVerifyAttention;
    private priceTimer: number | undefined = undefined;
    private isFetchingPrices = false;
    private lastFetchKey = '';

    dispatchPrices = () => {
        this._log(`dispatchPrices`);

        //Validate Basket manager state
        const valid = this.validateBm();
        if (!valid) {
            this._log(`fetchAndUpdatePrices-invalid`);
            return;
        }

        const fetchKey = generateRandomString();
        this.lastFetchKey = fetchKey;
        if (this.devOptions?.disablePriceFetch || this.devOptions?.disableAutoPriceFetch) {
            return;
        }
        // if (
        //     !validateSelectTickets(this.managerState, {
        //         savedPassengers: this.savedPassengers,
        //         savedVehicles: this.savedVehicles,
        //     })
        // ) {
        //     this.dispatchBasket((state) => {
        //         state.pricing.validation.status = 'invalid';

        //     });
        //     return;
        // }
        this.clearTimer();
        this.priceTimer = window.setTimeout(() => {
            //fetch server for prices
            this.fetchAndUpdatePrices(fetchKey);
        }, 2500);
    };

    private clearTimer = () => {
        if (this.priceTimer) {
            clearTimeout(this.priceTimer);
            this.priceTimer = undefined;
        }
    };

    //TODO Validate Basket manager
    private validateBm = () => {
        const bmState = this.getBmState();
        if (bmState.unselectedPets.length) {
            this._log(`fetchAndUpdatePrices-invalid - unselectedPets exist`);
            return false;
        }
        if (bmState.unselectedVehicles.length) {
            this._log(`fetchAndUpdatePrices-invalid - unselectedVehicles exist`);
            return false;
        }
        return true;
    };

    private dispatchLoadingPrices = () => {
        this.dispatchBasket((state) => {
            state.pricing.value = undefined;
            state.pricing.validation.status = 'loading';
            state.pricing.validation.error = undefined;
            state.pricing.trips.forEach((trip) => {
                trip.validation.error = undefined;
                trip.value = undefined;
                trip.passengers.forEach((pas) => {
                    pas.value = undefined;
                });
                trip.vehicles.forEach((pas) => {
                    pas.value = undefined;
                });
                trip.pets.forEach((pas) => {
                    pas.value = undefined;
                });
                if (trip.tariff) {
                    trip.tariff.value = undefined;
                }
            });
        });
    };

    fetchAndUpdatePrices = async (
        fetchKey?: string,
    ): Promise<{
        priceRes?: PriceClientVerifyRoutePriceResponseV2 | ErrorObject | undefined;
        quoteReset: boolean;
        vehicleRequired: boolean;
    }> => {
        let quoteReset = false;
        let vehicleRequired = false;
        this._log(`fetchAndUpdatePrices`);
        if (!fetchKey) {
            fetchKey = generateRandomString();
            this.lastFetchKey = fetchKey;
        }

        this.clearTimer();

        if (this.devOptions?.disablePriceFetch) {
            return {
                quoteReset,
                vehicleRequired,
            };
        }

        //Validate Basket manager state
        const valid = this.validateBm();
        if (!valid) {
            this._log(`fetchAndUpdatePrices-invalid`);
            return {
                quoteReset,
                vehicleRequired,
            };
        }

        //Start Fetching
        this.isFetchingPrices = true;
        this.dispatchLoadingPrices();
        this._log(`fetchAndUpdatePrices-fetch`, this.isFetchingPrices);
        const priceRes = await this._fetchPrice().catch((err) => {
            this._log(`fetchAndUpdatePrices-error`);
            if (this.lastFetchKey !== fetchKey) {
                return undefined;
            }
            if (isCodeMessageObject(err)) {
                return err;
            }
            const defaultError: ErrorObject = {
                code: '000',
                message: 'Something went wrong',
            };
            return defaultError;
        });

        //fetch key has changed
        if (!priceRes || this.lastFetchKey !== fetchKey) {
            return {
                quoteReset,
                vehicleRequired,
            };
        }

        //error detected
        if (isCodeMessageObject(priceRes)) {
            this.isFetchingPrices = false;
            this.handleErrorFetch(priceRes);
            return {
                priceRes,
                quoteReset,
                vehicleRequired,
            };
        }

        const bmState = this.getBmState();

        //Fetch Successful, Replace route price with prices
        this._log(`fetchAndUpdatePrices-mutating route price`);
        bmState.routePrice = priceRes.routePrice;

        const newTrips = priceRes.verifyAttention?.updatedTripSelected;

        if (newTrips) {
            this._log('fetchAndUpdatePrices-updating trips from verify');
            const vehicleRequiredBefore = !!bmState.basketDataProcessed.vehicles.required;
            await this.updateState({
                routePrice: bmState.routePrice,
                selectedTrips: newTrips,
                disableLowAvailability: true,
                disablePriceReset: true,
                disableErrorReset: true,
            });
            this.onTripsChanged?.(newTrips);
            const vehicleRequiredAfter = !!this.getBmState().basketDataProcessed.vehicles.required;
            if (vehicleRequiredBefore !== vehicleRequiredAfter) {
                vehicleRequired = true;
                quoteReset = true;
            }
        }

        this.dispatchAll();

        this.isFetchingPrices = false;

        //Handle Verify Attention
        this._handleVerifyAttention(priceRes.verifyAttention, {
            disableLowAvailability: true,
        });

        const attentionToShow = this.getVerifyAttention();
        if (attentionToShow) {
            if (!this.onVerifyAttention) {
                // throw 'MISSING onVerifyAttention';
                return {
                    priceRes,
                    quoteReset,
                    vehicleRequired,
                };
            }
            this.onVerifyAttention?.(attentionToShow);
        }

        return {
            priceRes,
            quoteReset,
            vehicleRequired,
        };
    };

    private _fetchPrice = async (): Promise<PriceClientVerifyRoutePriceResponseV2> => {
        if (this.devOptions?.mockPriceFetch) {
            return this._mockFetchPrice();
        }
        this._log(`_fetchPriceFromServer`);
        return this.verifyPrice(this.getBmState().routePrice)
            .then((res) => {
                if (isCodeMessageObject(res)) {
                    throw res;
                }
                this._log('fetchAndUpdatePrices-RES', res);
                return res;
            })
            .catch((err) => {
                if (isCodeMessageObject(err)) {
                    throw err;
                }
                const defaultError: ErrorObject = {
                    code: '000',
                    message: 'Something went wrong',
                };
                throw defaultError;
            });
    };

    private handleErrorFetch = (err: ErrorObject) => {
        this.dispatchBasket((state) => {
            state.pricing.validation.error = err.message;
            state.pricing.validation.status = 'verify';
            state.pricing.value = undefined;
        });
    };

    private dispatchAll = () => {
        const { routePrice } = this.getBmState();
        const { passengers, pets, vehicles } = routePrice;
        this.dispatchBasket((state) => {
            for (let tripIndex = 0; tripIndex < state.pricing.trips.length; tripIndex++) {
                state.pricing.trips[tripIndex].validation.error = routePrice.trips[tripIndex].error;
            }
            this.dispatchTariff({
                state,
                disablePriceReset: true,
                disableErrorReset: true,
                selectiveUpdate: {
                    pricing: true,
                },
            });
            for (let passengerIndex = 0; passengerIndex < passengers.length; passengerIndex++) {
                const passenger = routePrice.passengers[passengerIndex];
                this.dispatchPassenger(passenger, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
            for (let petIndex = 0; petIndex < pets.length; petIndex++) {
                const pet = routePrice.pets[petIndex];
                this.dispatchPet(pet, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
            for (let vehicleIndex = 0; vehicleIndex < vehicles.length; vehicleIndex++) {
                const vehicle = routePrice.vehicles[vehicleIndex];
                this.dispatchVehicle(vehicle, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
            this._injectPriceToState(state, routePrice);
        });
    };

    private _injectPriceToState = (state: ClientBasketState, routePrice: RoutePriceV2) => {
        state.pricing.trips.forEach((trip, index) => {
            trip.value = routePrice.trips[index].totalPrice;
        });
        state.pricing.value = routePrice.total;
        if (routePrice.total !== undefined) {
            state.pricing.validation.status = 'valid';
        } else {
            state.pricing.validation.status = 'verify';
        }
    };

    private _mockFetchPrice = () => {
        this._log(`_mockFetchPrice`);
        const bmState = this.getBmState();
        try {
            const newRoutePrice = cloneDeep(bmState.routePrice);
            let total = 0;
            newRoutePrice.trips.forEach((trip) => {
                const tripDic = this.getTripDictionary(trip.key);
                const tariffAdded =
                    (tripDic.tripTariffs?.findIndex((tariff) => tariff.tariffCode === trip.tariffReq?.tariffCode) ||
                        0) > 0;
                let tripTotal = 0;
                trip.error = undefined;

                const tariffExtra = tariffAdded ? Math.floor(Math.random() * 20) * 100 : 0;
                trip.passengerAccReq.forEach((pas) => {
                    const catDic = tripDic.passengerCatAccDic[pas.passengerData.catKey];
                    const accDic = catDic.accommodationsDic[pas.accCode];
                    const pasTotal = accDic.price;
                    if (pasTotal !== undefined) {
                        trip.accPrices[pas.passengerData.passengerId] = {
                            id: pas.passengerData.passengerId,
                            net: 0,
                            tax: 0,
                            total: pasTotal,
                        };
                        tripTotal = tripTotal + pasTotal + tariffExtra;
                    }
                    const extraKeys = Object.keys(pas.extras || {});
                    extraKeys.forEach((extraKey) => {
                        const extraItem = pas.extras![extraKey];
                        const extraAccDic = catDic.extrasDic[extraItem.code];
                        const extraTotal = extraAccDic.value || 0;
                        trip.accPrices[extraItem.extraId] = {
                            id: pas.passengerData.passengerId,
                            net: 0,
                            tax: 0,
                            total: extraTotal,
                        };
                        tripTotal = tripTotal + extraTotal;
                    });
                });

                trip.petAccReq.forEach((pet) => {
                    const petAcc = tripDic.petAccDic[pet.petCode];
                    const petTotal = petAcc.value;
                    if (petTotal !== undefined) {
                        trip.accPrices[pet.petId] = {
                            id: pet.petId,
                            net: 0,
                            tax: 0,
                            total: petTotal,
                        };
                        tripTotal = tripTotal + petTotal;
                    }
                });
                trip.vehicleAccReq.forEach((veh) => {
                    const catDic = tripDic.vehicleCatAccDic[veh.vehicleData.category];
                    const accDic = catDic.accommodationsDic[veh.accCode];
                    const vehTotal = accDic.price || Math.random() * 10000;
                    trip.accPrices[veh.vehicleData.vehicleId] = {
                        id: veh.vehicleData.vehicleId,
                        net: 0,
                        tax: 0,
                        total: vehTotal,
                    };
                    tripTotal = tripTotal + vehTotal + tariffExtra;
                });
                trip.totalPrice = tripTotal;
                total = total + tripTotal;
            });
            newRoutePrice.total = total;
            return new Promise<PriceClientVerifyRoutePriceResponseV2>((resolve) => {
                setTimeout(() => {
                    resolve({ routePrice: newRoutePrice });
                }, Math.random() * 1000);
            });
        } catch (err) {
            console.error(err);
            const defaultError: ErrorObject = {
                code: '000',
                message: 'Mock fetch error',
            };
            throw defaultError;
        }
    };

    //---------------VERIFY_ATTENTION---------------//

    getVerifyAttention = (): PriceClientVerifyAttention | undefined => {
        this._log('getVerifyAttention', { verifyAttention: !!this.verifyAttention });
        if (!this.verifyAttention) {
            return undefined;
        }
        const attention = { ...this.verifyAttention };
        this.verifyAttention = undefined;
        return attention;
    };

    private _handleVerifyAttention = (
        verifyAttention: PriceClientVerifyAttention | undefined,
        options?: {
            disableLowAvailability?: boolean;
        },
    ) => {
        if (!verifyAttention) {
            return;
        }
        const hasActionSheetData =
            verifyAttention.accChanged || verifyAttention.priceChanged || verifyAttention.updatedTripSelected;
        const hasAttentionData = verifyAttention.availabilityChanged || hasActionSheetData;
        if (!hasAttentionData) {
            return;
        }
        this._log('_handleVerifyAttention');
        if (options?.disableLowAvailability && verifyAttention.availabilityChanged && !hasActionSheetData) {
            return;
        }

        this.verifyAttention = verifyAttention;
        return verifyAttention;
    };

    //--------------------------------------------//

    /**
     * Async function to resume state. Make sure to call initiateCallBacks before
     * so that the state generates correct vehicle names which are fetched from server.
     * @param passedProps
     */
    resumeState = async (passedProps: ResumeStateProps) => {
        this._log('Resume state');
        const props = cloneDeep(passedProps);
        this.configOptions = passedProps.configOptions;
        this.initiateCallbacks(props);
        if (props.selectedTrips.length === 0) {
            this.resetBmState();
            throw createError({ code: 'TRIPS_MISSING' });
        }
        //
        const query = this.getQueryBreakdownFromRoutePrice(props.routePrice);

        const allProps = {
            ...props,
            query,
        };

        this.initiateBmState(allProps, props.routePrice);
        await this.refreshVehicleModelCache();
        const state = this.generateClientState(allProps);
        this.kickstartState(state);
        this.dispatchManager();
        //
        const { routePrice } = this.getBmState();
        this.dispatchBasketWrapper(undefined, (state) => {
            for (const passenger of routePrice.passengers) {
                this.dispatchPassenger(passenger, {
                    state,
                    disablePriceReset: passedProps.disablePriceReset,
                    disableErrorReset: passedProps.disableErrorReset,
                });
            }
            for (const vehicle of routePrice.vehicles) {
                this.dispatchVehicle(vehicle, {
                    state,
                    disablePriceReset: passedProps.disablePriceReset,
                    disableErrorReset: passedProps.disableErrorReset,
                });
            }
            for (const pet of routePrice.pets) {
                this.dispatchPet(pet, {
                    state,
                    disablePriceReset: passedProps.disablePriceReset,
                    disableErrorReset: passedProps.disableErrorReset,
                });
            }
            this.dispatchTariff({
                state,
                disablePriceReset: passedProps.disablePriceReset,
                disableErrorReset: passedProps.disableErrorReset,
            });
            this._injectPriceToState(state, routePrice);
        });
        this._handleVerifyAttention(props.verifyAttention, {
            disableLowAvailability: true,
        });
    };

    updateState = async (passedProps: UpdateStateProps) => {
        const verifyAttention = passedProps.verifyAttention;
        if (!verifyAttention?.updatedTripSelected && !passedProps.routePrice) {
            return;
        }
        const props = cloneDeep(passedProps);
        const bmState = this.getBmState();
        const routePrice = props.routePrice || bmState.routePrice;
        this._log('Update state');

        await this.resumeState({
            selectedTrips: passedProps.selectedTrips || verifyAttention?.updatedTripSelected || bmState.selectedTrips,
            savedPassengers: bmState.savedPassengers,
            savedVehicles: bmState.savedVehicles,
            savedPets: bmState.savedPets,
            //
            routePrice,
            verifyAttention: props.verifyAttention,
            disablePriceReset: props.disablePriceReset,
            disableErrorReset: props.disableErrorReset,
            configOptions: this.configOptions,
        });
        this._handleVerifyAttention(props.verifyAttention, {
            disableLowAvailability: props.disableLowAvailability,
        });
    };
}
