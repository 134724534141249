import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { updateDayjsLocale } from '../date';

export type SupportedLocales =
  | 'en-GB'
  | 'el-GR'
  | 'es-ES'
  | 'fr-FR'
  | 'nl-NL'
  | 'de-DE'
  | 'it-IT';

// Extracting the first two characters of SupportedLocales
export type SupportedLocaleCodes = Extract<
  SupportedLocales,
  `${string}-${string}`
> extends `${infer L}-${string}`
  ? L
  : never;

class LangManager {
  private _locale: SupportedLocales | undefined;
  initialized = false;

  initiate = async ({
    initialLocale,
    initialResources,
  }: {
    initialLocale: SupportedLocales;
    initialResources?: Resource;
  }) => {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this._locale = this.getSupportedLocaleFromThis(initialLocale).locale;

    await i18next
      .use(initReactI18next)
      .init({
        lng: this._locale, // if you're using a language detector, do not define the lng option
        debug: false,
        fallbackLng: 'en-GB',
        compatibilityJSON: 'v4',
        resources: initialResources,
      })
      .catch((error) => {
        console.error(error);
        this._locale = undefined;
        this.initialized = false;
        throw error;
      });
  };

  getCurrentLocale(): SupportedLocales {
    return this._locale ?? 'fr-FR';
  }

  getDeviceLocale(): string {
    if (typeof window !== 'undefined') {
      return Intl.DateTimeFormat().resolvedOptions().locale || navigator.language;
    }
    return 'en-GB'; // Default fallback
  }

  // Determines the best-matching supported locale based on input
  getSupportedLocaleFromThis(initialLocaleOrPageLang: string): {
    locale: SupportedLocales;
    localeCode: SupportedLocaleCodes;
  } {
    const supportedLanguages = this.getSupportedLanguages();

    if (typeof window !== 'undefined') {
      const deviceLocale = this.getDeviceLocale();

      // If the device locale fully includes the initial locale, prefer it
      if (deviceLocale.includes(initialLocaleOrPageLang)) {
        initialLocaleOrPageLang = deviceLocale;
      }

      // Try to match the full locale (e.g., "fr-FR", "en-GB")
      const foundLocale = supportedLanguages.find(
        ({ value }) => value === initialLocaleOrPageLang,
      );

      if (foundLocale) {
        return {
          locale: foundLocale.value,
          localeCode: foundLocale.code,
        };
      }

      // Try to match just the language part (e.g., "fr", "en")
      const langCode = initialLocaleOrPageLang.slice(0, 2);
      const foundLang = supportedLanguages.find(({ value }) =>
        value.startsWith(langCode),
      );
      if (foundLang) {
        return {
          locale: foundLang.value,
          localeCode: foundLang.code,
        };
      }
    }

    const foundLocale = supportedLanguages.find(
      (supportedLang) =>
        supportedLang.code === initialLocaleOrPageLang ||
        supportedLang.value === initialLocaleOrPageLang,
    );
    if (foundLocale) {
      return {
        locale: foundLocale.value,
        localeCode: foundLocale.code,
      };
    }

    throw new Error(`${initialLocaleOrPageLang} is not a supported lang at the moment`);
  }

  getSupportedLanguages = (): {
    value: SupportedLocales;
    code: SupportedLocaleCodes;
    label: string;
  }[] => {
    return [
      { value: 'en-GB', label: 'English', code: 'en' },
      { value: 'de-DE', label: 'Deutsch', code: 'de' },
      { value: 'el-GR', label: 'Ελληνικά', code: 'el' },
      { value: 'es-ES', label: 'Español', code: 'es' },
      { value: 'fr-FR', label: 'Français', code: 'fr' },
      { value: 'it-IT', label: 'Italiano', code: 'it' },
      { value: 'nl-NL', label: 'Nederlands', code: 'nl' },
    ];
  };

  loadTranslation = (lang: SupportedLocales, file: any) => {
    i18next.addResourceBundle(lang, 'translation', file);
  };

  changeLanguage = async (locale: SupportedLocales) => {
    this._locale = locale;
    updateDayjsLocale();
    return i18next.changeLanguage(locale);
  };

  getI18NextInstance = () => {
    return i18next as any;
  };
}

export const i18nManager = new LangManager();
