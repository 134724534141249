import { BasketManagerOptions } from './providers/bm.0.base';
import { BasketManagerInternal } from './providers';
import { BookClientCreateSessionRequestV2, KeyVal, RoutePriceV2, RouteTrip } from '@naus-code/naus-client-types';
import { mapArrayToKeyValue } from './utils/functions';

export class BasketManager {
    private bm;

    constructor(options: BasketManagerOptions) {
        this.bm = new BasketManagerInternal(options);
        return new Proxy(this, {
            get: (target, prop) => {
                const original = Reflect.get(target, prop);
                // Return non-function properties as-is
                if (typeof original !== 'function') {
                    return original;
                }
                // Wrap function with error handling
                const boundFunction = original.bind(target);
                return (...args: any[]) => {
                    try {
                        const result = boundFunction(...args);
                        if (result instanceof Promise) {
                            return result.catch((error) => {
                                this.handleLogError(error, prop, args, options);
                                throw error;
                            });
                        }
                        return result;
                    } catch (error) {
                        this.handleLogError(error, prop, args, options);
                        throw error;
                    }
                };
            },
        });
    }

    private handleLogError(error: unknown, prop: string | symbol, args: any[], options: BasketManagerOptions) {
        const additionalContext = this.getErrorContext();
        options.logError?.({
            error,
            method: String(prop),
            arguments: args,
            context: additionalContext,
        });
    }

    private getErrorContext() {
        try {
            // Safely retrieve any relevant context
            const routePrice = this?.getRoutePrice?.();
            const selectTrips = this?.getSelectedTrips?.();
            return {
                routePrice,
                selectTrips,
                // Add any other relevant properties or states here
            };
        } catch {
            return { error: 'Failed to retrieve context' };
        }
    }

    //---------------INITIATE---------------//

    initiateCallbacks: BasketManagerInternal['initiateCallbacks'] = (...args) => {
        return this.bm.initiateCallbacks(...args);
    };

    initiateState: BasketManagerInternal['initiateState'] = (...args) => {
        const res = this.bm.initiateState(...args);
        const { basketDataProcessed, savedPassengers } = this.bm.getBmState();
        const adults = Math.min(Math.max(args[0].query.adult, 1), basketDataProcessed.passengers.limit);
        const children = Math.min(Math.max(args[0].query.child || 0, 0), basketDataProcessed.passengers.limit - adults);
        this.bm.dispatchBasketWrapper(undefined, (state) => {
            for (let pIndex = 0; pIndex < adults; pIndex++) {
                const defaultSaved =
                    pIndex === 0 && savedPassengers.length ? savedPassengers.find((item) => item.isDefault) : undefined;
                if (defaultSaved) {
                    this.bm.addPassengerPrivate(
                        {
                            passenger: {
                                id: defaultSaved.id,
                                type: 'saved',
                            },
                            state,
                        },
                        { disableDictionaryRefresh: true },
                    );
                } else {
                    this.bm.addPassenger({ state });
                }
            }
            for (let pIndex = 0; pIndex < children; pIndex++) {
                this.bm.addPassengerPrivate({ state }, { isChild: true });
            }
            this.bm.dispatchTariff({ state });
        });
        return res;
    };

    resumeState: BasketManagerInternal['resumeState'] = (...args) => {
        return this.bm.resumeState(...args);
    };

    updateState: BasketManagerInternal['updateState'] = (...args) => {
        return this.bm.updateState(...args);
    };

    // resumeState: BasketManagerInternal['resumeState'] = (...args) => {
    //     return this.bm.resumeState(...args);
    // };

    getInitialState: BasketManagerInternal['getInitialState'] = (...args) => {
        return this.bm.getInitialState(...args);
    };

    setBasketStateListener: BasketManagerInternal['setBasketStateListener'] = (...args) => {
        return this.bm.setBasketStateListener(...args);
    };

    continueToPrice: BasketManagerInternal['continueToPrice'] = (...args) => {
        return this.bm.continueToPrice(...args);
    };

    continueToDetails: BasketManagerInternal['continueToDetails'] = (...args) => {
        return this.bm.continueToDetails(...args);
    };

    //---------------QUOTE DETAILS---------------//
    getQueryBreakdown: BasketManagerInternal['getQueryBreakdown'] = (...args) => {
        return this.bm.getQueryBreakdown(...args);
    };

    //---------------PASSENGER---------------//
    addPassenger: BasketManagerInternal['addPassenger'] = (...args) => {
        return this.bm.addPassenger(...args);
    };

    removePassenger: BasketManagerInternal['removePassenger'] = (...args) => {
        return this.bm.removePassenger(...args);
    };

    getPassengerList: BasketManagerInternal['getPassengerList'] = (...args) => {
        return this.bm.getPassengerList(...args);
    };

    setPassenger: BasketManagerInternal['setPassenger'] = (...args) => {
        return this.bm.setPassenger(...args);
    };

    //Pricing
    selectPassengerTicket: BasketManagerInternal['selectPassengerTicket'] = (...args) => {
        return this.bm.selectPassengerTicket(...args);
    };

    // selectPassengerGroupTicket: BasketManagerInternal['selectPassengerGroupTicket'] = (...args) => {
    //     return this.bm.selectPassengerGroupTicket(...args);
    // };

    selectPassengerDiscount: BasketManagerInternal['selectPassengerDiscount'] = (...args) => {
        return this.bm.selectPassengerDiscount(...args);
    };

    selectPassengerLoyalty: BasketManagerInternal['selectPassengerLoyalty'] = (...args) => {
        return this.bm.selectPassengerLoyalty(...args);
    };

    addExtra: BasketManagerInternal['addExtra'] = (...args) => {
        return this.bm.addExtra(...args);
    };

    removeExtra: BasketManagerInternal['removeExtra'] = (...args) => {
        return this.bm.removeExtra(...args);
    };

    //Pricing details
    getPassengerDetailsForPrice: BasketManagerInternal['getPassengerDetailsForPrice'] = (...args) => {
        return this.bm.getPassengerDetailsForPrice(...args);
    };

    updatePassengerDetailsForPrice: BasketManagerInternal['updatePassengerDetailsForPrice'] = (...args) => {
        return this.bm.updatePassengerDetailsForPrice(...args);
    };

    //Details
    updatePassengerDetails: BasketManagerInternal['updatePassengerDetails'] = (...args) => {
        return this.bm.updatePassengerDetails(...args);
    };

    extraPassengerDetailsFields: BasketManagerInternal['extraPassengerDetailsFields'] = (...args) => {
        return this.bm.extraPassengerDetailsFields(...args);
    };

    enablePassengerDetail: BasketManagerInternal['enablePassengerDetail'] = (...args) => {
        return this.bm.enablePassengerDetail(...args);
    };

    disablePassengerDetail: BasketManagerInternal['disablePassengerDetail'] = (...args) => {
        return this.bm.disablePassengerDetail(...args);
    };

    //---------------PET---------------//

    //Quote
    getPetAddState: BasketManagerInternal['getPetAddState'] = (...args) => {
        return this.bm.getPetAddState(...args);
    };

    setPetAddStatePet: BasketManagerInternal['setPetAddStatePet'] = (...args) => {
        return this.bm.setPetAddStatePet(...args);
    };

    setPetAddStateWeight: BasketManagerInternal['setPetAddStateWeight'] = (...args) => {
        return this.bm.setPetAddStateWeight(...args);
    };

    setPetAddStatePassenger: BasketManagerInternal['setPetAddStatePassenger'] = (...args) => {
        return this.bm.setPetAddStatePassenger(...args);
    };

    //Add

    addPet: BasketManagerInternal['addPet'] = (...args) => {
        return this.bm.addPet(...args);
    };

    removePet: BasketManagerInternal['removePet'] = (...args) => {
        return this.bm.removePet(...args);
    };

    //Pricing
    selectPetTicket: BasketManagerInternal['selectPetTicket'] = (...args) => {
        return this.bm.selectPetTicket(...args);
    };

    //Pricing details
    getPetDetailsForPrice: BasketManagerInternal['getPetDetailsForPrice'] = (...args) => {
        return this.bm.getPetDetailsForPrice(...args);
    };

    updatePetDetailsForPrice: BasketManagerInternal['updatePetDetailsForPrice'] = (...args) => {
        return this.bm.updatePetDetailsForPrice(...args);
    };

    //Details
    updatePetDetails: BasketManagerInternal['updatePetDetails'] = (...args) => {
        return this.bm.updatePetDetails(...args);
    };

    extraPetDetailsFields: BasketManagerInternal['extraPetDetailsFields'] = (...args) => {
        return this.bm.extraPetDetailsFields(...args);
    };

    enablePetDetail: BasketManagerInternal['enablePetDetail'] = (...args) => {
        return this.bm.enablePetDetail(...args);
    };

    disablePetDetail: BasketManagerInternal['disablePetDetail'] = (...args) => {
        return this.bm.disablePetDetail(...args);
    };

    //---------------VEHICLE---------------//

    //Quote
    getVehicleAddState: BasketManagerInternal['getVehicleAddState'] = (...args) => {
        return this.bm.getVehicleAddState(...args);
    };

    setVehicleAddStateCategory: BasketManagerInternal['setVehicleAddStateCategory'] = (...args) => {
        return this.bm.setVehicleAddStateCategory(...args);
    };

    //Car
    setVehicleAddStateCarMake: BasketManagerInternal['setVehicleAddStateCarMake'] = (...args) => {
        return this.bm.setVehicleAddStateCarMake(...args);
    };

    setVehicleAddStateCarModel: BasketManagerInternal['setVehicleAddStateCarModel'] = (...args) => {
        return this.bm.setVehicleAddStateCarModel(...args);
    };

    setVehicleAddStateSize: BasketManagerInternal['setVehicleAddStateSize'] = (...args) => {
        return this.bm.setVehicleAddStateSize(...args);
    };

    setVehicleAddStateEngine: BasketManagerInternal['setVehicleAddStateEngine'] = (...args) => {
        return this.bm.setVehicleAddStateEngine(...args);
    };

    setVehicleEnableStateAddon: BasketManagerInternal['setVehicleEnableStateAddon'] = (...args) => {
        return this.bm.setVehicleEnableStateAddon(...args);
    };

    setVehicleDisableStateAddon: BasketManagerInternal['setVehicleDisableStateAddon'] = (...args) => {
        return this.bm.setVehicleDisableStateAddon(...args);
    };

    setVehicleAddStateTrailer: BasketManagerInternal['setVehicleAddStateTrailer'] = (...args) => {
        return this.bm.setVehicleAddStateTrailer(...args);
    };

    setVehicleAddStateRoof: BasketManagerInternal['setVehicleAddStateRoof'] = (...args) => {
        return this.bm.setVehicleAddStateRoof(...args);
    };

    //Moto
    setVehicleAddStateMotoCategory: BasketManagerInternal['setVehicleAddStateMotoCategory'] = (...args) => {
        return this.bm.setVehicleAddStateMotoCategory(...args);
    };

    setVehicleAddStateMotoMake: BasketManagerInternal['setVehicleAddStateMotoMake'] = (...args) => {
        return this.bm.setVehicleAddStateMotoMake(...args);
    };

    //Add
    addVehicle: BasketManagerInternal['addVehicle'] = (...args) => {
        return this.bm.addVehicle(...args);
    };

    removeVehicle: BasketManagerInternal['removeVehicle'] = (...args) => {
        return this.bm.removeVehicle(...args);
    };

    //Pricing

    selectVehicleDriver: BasketManagerInternal['selectVehicleDriver'] = (...args) => {
        return this.bm.selectVehicleDriver(...args);
    };

    selectVehicleDiscount: BasketManagerInternal['selectVehicleDiscount'] = (...args) => {
        return this.bm.selectVehicleDiscount(...args);
    };

    getVehicleDetailsForPrice: BasketManagerInternal['getVehicleDetailsForPrice'] = (...args) => {
        return this.bm.getVehicleDetailsForPrice(...args);
    };

    updateVehicleDetailsForPrice: BasketManagerInternal['updateVehicleDetailsForPrice'] = (...args) => {
        return this.bm.updateVehicleDetailsForPrice(...args);
    };

    //Details
    updateVehicleDetails: BasketManagerInternal['updateVehicleDetails'] = (...args) => {
        return this.bm.updateVehicleDetails(...args);
    };

    extraVehicleDetailsFields: BasketManagerInternal['extraVehicleDetailsFields'] = (...args) => {
        return this.bm.extraVehicleDetailsFields(...args);
    };

    enableVehicleDetail: BasketManagerInternal['enableVehicleDetail'] = (...args) => {
        return this.bm.enableVehicleDetail(...args);
    };

    disableVehicleDetail: BasketManagerInternal['disableVehicleDetail'] = (...args) => {
        return this.bm.disableVehicleDetail(...args);
    };

    //---------------Tariff---------------//

    //Pricing
    selectTariff: BasketManagerInternal['selectTariff'] = (...args) => {
        return this.bm.selectTariff(...args);
    };

    //---------------PRICE---------------//

    fetchAndUpdatePrices: BasketManagerInternal['fetchAndUpdatePrices'] = (...args) => {
        return this.bm.fetchAndUpdatePrices(...args);
    };

    setOnVerifyAttention = (...args: any) => {
        args;
        // return this.bm.setOnVerifyAttention(...args);
    };

    getVerifyAttention: BasketManagerInternal['getVerifyAttention'] = (...args) => {
        return this.bm.getVerifyAttention(...args);
    };

    //---------------UTILS---------------//

    getSelectedTrips = (): RouteTrip[] => {
        return this.bm.getBmState().selectedTrips;
    };

    getSelectedTripMap = (): KeyVal<RouteTrip> => {
        return mapArrayToKeyValue(this.bm.getBmState().selectedTrips, (trip) => trip.key);
    };

    getRouteTrip = (tripKey: string): RouteTrip | undefined => {
        return this.bm.getBmState().tripDictionaries[tripKey];
    };

    getRoutePrice = (): RoutePriceV2 => {
        return this.bm.getBmState().routePrice;
    };

    getSessionRequestData = (): BookClientCreateSessionRequestV2 => {
        const bmState = this.bm.getBmState();
        return {
            routePrice: bmState.routePrice,
        };
    };

    //---------------STATIC---------------//
    static getQueryBreakdownFromRoutePrice = BasketManagerInternal.getQueryBreakdownFromRoutePrice;
}
