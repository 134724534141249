import {
    CustomerClientPassenger,
    CustomerClientPetV2,
    CustomerClientVehicleV2,
    KeyVal,
    Optional,
    PriceClientVerifyAttention,
    PriceClientVerifyLoyaltyRequest,
    PriceClientVerifyLoyaltyResponseV2,
    PriceClientVerifyRoutePriceResponseV2,
    RoutePassengerAccommodationClient,
    RoutePassengerAccommodationDictionaryClient,
    RoutePassengerCategoryV2,
    RoutePricePassengerV2,
    RoutePriceV2,
    RouteTrip,
    RouteTripDictionaryClient,
    SearchClientQuoteDetails,
    TicketVehicleCategory,
    TicketVehicleMotoCategory,
    TripPriceTariffAccommodation,
    VehicleClientGetMakesResponse,
    VehicleClientGetModelResponse,
    VehicleClientGetModelsResponse,
    VehicleModelClient,
} from '@naus-code/naus-client-types';
import { BasketManagerOptions, BasketMangerBase } from './bm.0.base';
import { BasketManagerState, ManagerBasketDataProcessed, ProcessedPassengerCategory } from '../types/managerState';
import {
    cloneDeep,
    generateRandomString,
    getKeysOfObject,
    mapArrayToKeyValue,
    onlyUnique,
    sortSmallestFirst,
} from '../utils/functions';
import { createError } from '../errors';
import { SelectiveUpdate } from '../types/providerCommon';
import {
    ClientBasketState,
    InitiateQuery,
    PetUnselectedListItem,
    VehicleUnselectedListItem,
} from '../types/clientState';
import dayjs from 'dayjs';

interface InitiateConfigOptions {
    skipAccommodationSelectionIfAvailable?: boolean;
}

export interface InitiateStateProps extends Optional<InitiateCallbackProps> {
    selectedTrips: RouteTrip[];
    savedPassengers: CustomerClientPassenger[];
    savedVehicles: CustomerClientVehicleV2[];
    savedPets: CustomerClientPetV2[];
    query: InitiateQuery;
    configOptions: InitiateConfigOptions | undefined;
}

export interface InitiateCallbackProps {
    onTripsChanged?: (trips: RouteTrip[]) => void;
    //Translate
    translate: BasketManagerOptions['translate'];
    //Price
    onVerifyPrice: (routePrice: RoutePriceV2) => Promise<PriceClientVerifyRoutePriceResponseV2>;
    onVerifyAttention: (verifyAttention: PriceClientVerifyAttention) => void | undefined;
    //Loyalty
    onVerifyLoyalty: (options: PriceClientVerifyLoyaltyRequest) => Promise<PriceClientVerifyLoyaltyResponseV2>;
    //Cars
    OnCarMakeList?: () => Promise<VehicleClientGetMakesResponse>;
    OnCarModelList?: (makeId: string) => Promise<VehicleClientGetModelsResponse>;
    OnCarModelId?: (modelId: string) => Promise<VehicleClientGetModelResponse>;
    //Moto
    OnMotoMakeList?: () => Promise<VehicleClientGetMakesResponse>;
    OnMotoModelList?: (makeId: string) => Promise<VehicleClientGetModelsResponse>;
    OnMotoModelId?: (modelId: string) => Promise<VehicleClientGetModelResponse>;
}

export class BasketManagerSetup extends BasketMangerBase {
    OnCarMakeList: InitiateCallbackProps['OnCarMakeList'];
    OnCarModelList: InitiateCallbackProps['OnCarModelList'];
    OnCarModelId: InitiateCallbackProps['OnCarModelId'];
    OnMotoMakeList: InitiateCallbackProps['OnMotoMakeList'];
    OnMotoModelList: InitiateCallbackProps['OnMotoModelList'];
    OnMotoModelId: InitiateCallbackProps['OnMotoModelId'];
    onTripsChanged: InitiateCallbackProps['onTripsChanged'];
    onVerifyAttention: InitiateCallbackProps['onVerifyAttention'] | undefined;
    translate;

    dispatchManager: () => void;
    private bmState?: BasketManagerState;
    configOptions?: InitiateConfigOptions;
    getBmState = (): BasketManagerState => {
        if (!this.bmState) {
            throw createError({ code: 'BM_NOT_INITIALIZED' });
        }
        return this.bmState;
    };
    resetBmState = () => {
        this.bmState = undefined;
    };

    constructor(options: BasketManagerOptions) {
        super(options);
        this.dispatchManager = () => options.dispatchManager(this.getBmState());
        this.translate = options.translate;
    }

    getInitialState = (): ClientBasketState => {
        return {
            quoteOptions: {
                passengers: {
                    limit: 0,
                    canAddPassenger: false,
                    canRemovePassenger: false,
                    list: [],
                },
                pets: {
                    limit: 0,
                    canAddPet: false,
                    canRemovePet: false,
                    list: [],
                    unselectedList: [],
                },
                vehicles: {
                    limit: 0,
                    canAddVehicle: false,
                    canRemoveVehicle: false,
                    vehiclesAllowed: true,
                    vehiclesMandatory: false,
                    vehiclesSoldOut: false,
                    list: [],
                    unselectedList: [],
                },
                query: {
                    adult: 1,
                    child: 0,
                    moto: 0,
                    pet: 0,
                    car: 0,
                    trailer: 0,
                    camper: 0,
                    bus: 0,
                    bicycle: 0,
                },
                validation: {
                    continue: false,
                },
            },
            pricing: {
                trips: [],
                validation: {
                    status: 'invalid',
                },
                ccy: {
                    value: 'EUR',
                    symbol: '€',
                },
                value: undefined,
            },
            details: {
                showQuoteOptions: false,
                passengers: [],
                pets: [],
                vehicles: [],
                validation: {
                    continue: false,
                },
            },
            flowConfig: {
                skipTripPricingDetails: false,
                skipPricingPage: false,
                displayAccommodationText: false,
                showAccAsUpgrade: false,
            },
        };
    };

    initiateCallbacks = (props: Optional<InitiateCallbackProps>) => {
        if (props.onVerifyAttention) {
            this.onVerifyAttention = props.onVerifyAttention;
        }
        if (props.onTripsChanged) {
            this.onTripsChanged = props.onTripsChanged;
        }
        if (props.translate) {
            this.translate = props.translate;
        }
        if (props.onVerifyPrice) {
            this.onVerifyPrice = props.onVerifyPrice;
        }
        if (props.onVerifyLoyalty) {
            this.onVerifyLoyalty = props.onVerifyLoyalty;
        }
        if (props.OnCarMakeList) {
            this.OnCarMakeList = props.OnCarMakeList;
        }
        if (props.OnCarModelList) {
            this.OnCarModelList = props.OnCarModelList;
        }
        if (props.OnCarModelId) {
            this.OnCarModelId = props.OnCarModelId;
        }
        if (props.OnMotoMakeList) {
            this.OnMotoMakeList = props.OnMotoMakeList;
        }
        if (props.OnMotoModelList) {
            this.OnMotoModelList = props.OnMotoModelList;
        }
        if (props.OnMotoModelId) {
            this.OnMotoModelId = props.OnMotoModelId;
        }
    };

    initiateState = (passedProps: InitiateStateProps) => {
        this.configOptions = passedProps.configOptions;
        const props = cloneDeep(passedProps);
        this.initiateCallbacks(props);
        if (props.selectedTrips.length === 0) {
            this.resetBmState();
            throw createError({ code: 'TRIPS_MISSING' });
        }
        this.initiateBmState(props);
        const state = this.generateClientState(props);
        this.kickstartState(state);
        this.dispatchManager();
        return state;
    };

    initiateBmState = (props: InitiateStateProps, routePrice?: RoutePriceV2) => {
        const passengerCount = (props.query.adult || 1) + (props.query.child || 1);
        const tripDictionaries = mapArrayToKeyValue(
            props.selectedTrips,
            (item) => item.key,
            (item, index) => this.mapTripDictionary(item, index, passengerCount),
        );
        // console.log(
        //     Object.values(tripDictionaries).map((item) =>
        //         Object.values(item.passengerCatAccDic).map((catDic) => ({
        //             key: catDic.cat,
        //             defaultAcc: catDic.defaultAcc,
        //         })),
        //     ),
        // );
        const validSavedVehicles = props.savedVehicles.filter((veh) => veh.model || veh.size);
        const basketDataProcessed = this.getBasketDataProcessed(props.selectedTrips, tripDictionaries);

        const { unselectedPets, unselectedVehicles } = this.getUnselectedLists(props, basketDataProcessed, routePrice);

        this.bmState = {
            selectedTrips: props.selectedTrips,
            //
            savedPassengers: props.savedPassengers,
            savedPassengersDic: mapArrayToKeyValue(props.savedPassengers, (item) => item.id),
            //
            savedVehicles: validSavedVehicles,
            savedVehiclesDic: mapArrayToKeyValue(validSavedVehicles, (item) => item.id),
            savedPets: props.savedPets,
            savedPetsDic: mapArrayToKeyValue(props.savedPets, (item) => item.id),
            //
            options: this.getOptions(props.selectedTrips),
            tripDictionaries,
            //
            unselectedPets,
            unselectedVehicles,
            //
            basketDataProcessed,
            routePrice: routePrice || this.generateRoutePrice(props.selectedTrips),
        };
    };

    private emptyQuoteDetails: SearchClientQuoteDetails = {
        adult: 0,
        child: 0,
        moto: 0,
        pet: 0,
        car: 0,
        trailer: 0,
        camper: 0,
        bus: 0,
        bicycle: 0,
    };
    private getSearchQuoteDetailsFromRoutePrice = (routePrice: RoutePriceV2) => {
        const quoteDetails: SearchClientQuoteDetails = this.emptyQuoteDetails;

        routePrice.passengers.forEach((pas) => {
            if (pas.birthDate) {
                const age = dayjs().diff(dayjs(pas.birthDate), 'years');
                if (age <= 6) {
                    quoteDetails.child++;
                } else {
                    quoteDetails.adult++;
                }
                return;
            }
            const averageAge = pas.minAge + (pas.maxAge - pas.minAge) / 2;
            if (averageAge <= 6) {
                quoteDetails.child++;
                return;
            }
            quoteDetails.adult++;
        });

        routePrice.vehicles.forEach((veh) => {
            switch (veh.category) {
                case 'car':
                    quoteDetails.car++;
                    break;
                case 'moto':
                case 'trike':
                case 'quad':
                    quoteDetails.moto++;
                    break;
                case 'bike':
                    quoteDetails.bicycle++;
                    break;
                case 'camper':
                    quoteDetails.camper++;
                    break;
                case 'trailer':
                    quoteDetails.trailer++;
                    break;
                case 'bus':
                    quoteDetails.bus++;
                    break;
                default:
                    break;
            }
        });
        return quoteDetails;
    };

    private getUnselectedLists = (
        options: InitiateStateProps,
        basketDataProcessed: ManagerBasketDataProcessed,
        routePrice?: RoutePriceV2,
    ) => {
        const unselectedPets: PetUnselectedListItem[] = [];
        const unselectedVehicles: VehicleUnselectedListItem[] = [];

        // if (routePrice) {
        //     return { unselectedPets, unselectedVehicles };
        // }

        //Pets
        for (let index = 0; index < options.query.pet; index++) {
            if (unselectedPets.length < basketDataProcessed.pets.limit) {
                unselectedPets.push({});
            }
        }
        if (!basketDataProcessed.vehicles.allowed) {
            return { unselectedPets, unselectedVehicles };
        }

        //Vehicles
        const vehiclesFromQuery = routePrice
            ? this.getSearchQuoteDetailsFromRoutePrice(routePrice)
            : this.emptyQuoteDetails;

        for (let index = 0; index < Math.max(0, options.query.car - vehiclesFromQuery.car); index++) {
            if (unselectedVehicles.length < basketDataProcessed.vehicles.limit) {
                unselectedVehicles.push({
                    // category: 'car'
                });
            }
        }
        for (let index = 0; index < Math.max(0, options.query.moto - vehiclesFromQuery.moto); index++) {
            if (unselectedVehicles.length < basketDataProcessed.vehicles.limit) {
                unselectedVehicles.push({
                    // category: 'moto'
                });
            }
        }
        for (let index = 0; index < Math.max(0, options.query.camper - vehiclesFromQuery.camper); index++) {
            if (unselectedVehicles.length < basketDataProcessed.vehicles.limit) {
                unselectedVehicles.push({
                    // category: 'camper'
                });
            }
        }
        for (let index = 0; index < Math.max(0, options.query.bus - vehiclesFromQuery.bus); index++) {
            if (unselectedVehicles.length < basketDataProcessed.vehicles.limit) {
                unselectedVehicles.push({
                    // category: 'bus'
                });
            }
        }
        for (let index = 0; index < Math.max(0, options.query.bicycle - vehiclesFromQuery.bicycle); index++) {
            if (unselectedVehicles.length < basketDataProcessed.vehicles.limit) {
                unselectedVehicles.push({
                    // category: 'bike'
                });
            }
        }
        if (!unselectedVehicles.length && !routePrice?.vehicles.length && basketDataProcessed.vehicles.required) {
            unselectedVehicles.push({
                // category: 'car'
            });
        }
        return { unselectedPets, unselectedVehicles };
    };

    kickstartState = (state: ClientBasketState) => {
        this.dispatchBasketWrapper(undefined, (basketState) => {
            const keys = getKeysOfObject(state);
            keys.forEach((key) => {
                (basketState as any)[key] = state[key];
            });
        });
    };

    private getOptions = (selectedTrips: RouteTrip[]): BasketManagerState['options'] => {
        return {
            ccy: selectedTrips[0].ccy,
            ccySymbol: selectedTrips[0].ccySymbol,
        };
    };

    //---------------TRIP_DICTIONARY---------------//
    private mapTripDictionary = (
        trip: RouteTrip,
        index: number,
        numberOfPassengers: number,
    ): RouteTripDictionaryClient => {
        return {
            ...trip,
            index,
            tripTariffsDic: mapArrayToKeyValue(trip.tripTariffs || [], (item) => item.tariffCode),
            passengerCatAccDic: this.getPassengerCatAccDic(trip, numberOfPassengers),
            passengerAccDic: mapArrayToKeyValue(trip.passengerAcc, (acc) => acc.code, this.getPassengerAccDic),
            //
            petAccDic: mapArrayToKeyValue(trip.petAcc || [], (acc) => acc.code),
            petCatAccDic: this.getPetCatAccDic(trip),
            //
            vehicleCatAccDic: this.getVehicleCatAccDic(trip),
            //
            extraAccDic: {},
        };
    };

    //Passengers
    private getPassengerAccDic = (
        acc: RoutePassengerAccommodationClient,
    ): RoutePassengerAccommodationDictionaryClient => {
        return {
            ...acc,
            discountsDic: acc.discounts ? mapArrayToKeyValue(acc.discounts, (acc) => acc.companyCode) : undefined,
        };
    };

    getPassengerCatKey = (cat: RoutePassengerCategoryV2) => {
        return `${cat.minAge}-${cat.maxAge}`;
    };

    private getPassengerCatAccDic = (trip: RouteTrip, numberOfPassengers: number) => {
        const cache: KeyVal<RouteTripDictionaryClient['passengerCatAccDic'][0]> = {};

        for (const cat of trip.passengerCatAcc) {
            const key = this.getPassengerCatKey(cat.cat);
            const accommodations = (cat.accommodations?.length ? cat.accommodations : trip.passengerAcc).map(
                this.getPassengerAccDic,
            );
            const { filteredAcc, defaultAcc } = this.getFilteredAndDefaultAcc(trip, accommodations, numberOfPassengers);
            cache[key] = {
                ...cat,
                extras: cat.extras || trip.extraAcc,
                extrasDic: mapArrayToKeyValue(cat.extras || trip.extraAcc, (extra) => extra.code),
                accommodations,
                accommodationsDic: mapArrayToKeyValue(accommodations, (acc) => acc.code),
                filteredAcc,
                defaultAcc,
                discountsDic: mapArrayToKeyValue(cat.discounts, (acc) => acc.companyCode),
            };
        }

        return cache;
    };

    static adultAge = 40;
    static childAge = 6;

    private getBasketDataProcessed = (
        selectedTrips: RouteTrip[],
        tripDictionaries: KeyVal<RouteTripDictionaryClient>,
    ): ManagerBasketDataProcessed => {
        const categoryList = this.getPassengerCategories(selectedTrips);
        const adultCategory = categoryList.find(
            (item) => item.minAge <= BasketManagerSetup.adultAge && BasketManagerSetup.adultAge <= item.maxAge,
        );
        if (!adultCategory) {
            throw createError({ code: 'NO_DEFAULT_AGE_GROUP' });
        }
        const childCategory = categoryList.find(
            (item) => item.minAge <= BasketManagerSetup.childAge && BasketManagerSetup.childAge <= item.maxAge,
        );

        const base: ManagerBasketDataProcessed = {
            passengers: {
                limit: 9,
                adultCategory,
                childCategory,
                categoryList,
                validation: {},
            },
            pets: {
                limit: 4,
                validation: {},
            },
            vehicles: {
                limit: 4,
                allowed: true,
                soldOut: false,
                required: false,
                validation: {},
            },
            trips: {
                validation: {},
            },
            flowConfig: {
                skipPricingPage: false,
                skipTripPricingDetails: true,
                groupPassengerAccommodation: false,
                hideVehicleDetails: true,
                displayAccommodationText: true,
                showAccAsUpgrade: true,
            },
        };
        for (const trip of selectedTrips) {
            const dictionary = tripDictionaries[trip.key];
            //check if this applies per passenger or not.
            if (!trip.routeConfig?.config?.skipPricingPageIfNoDetails || dictionary.passengerAcc.length > 1) {
                base.flowConfig.skipPricingPage = false;
            }
            if (!trip.routeConfig?.config?.skipTripPricingIfNoDetails || dictionary.passengerAcc.length > 1) {
                base.flowConfig.skipTripPricingDetails = false;
            }
            if (!trip.routeConfig?.config?.showAccAsUpgrade) {
                base.flowConfig.showAccAsUpgrade = false;
            }
            if (!trip.routeConfig?.config?.groupPassengerAccommodation) {
                base.flowConfig.groupPassengerAccommodation = false;
            }
            if (!trip.routeConfig?.config?.hideVehicleDetails) {
                base.flowConfig.hideVehicleDetails = false;
            }
            if (!trip.routeConfig?.config?.displayAccommodationText) {
                base.flowConfig.displayAccommodationText = false;
            }
            base.passengers.validation = {
                ...base.passengers.validation,
                ...trip.passengerVal,
            };
            base.vehicles.validation = {
                ...base.vehicles.validation,
                ...trip.vehicleVal,
            };
            base.pets.validation = {
                ...base.pets.validation,
                ...trip.petVal,
            };

            base.trips.validation = {
                ...base.trips.validation,
                ...trip.tripValidation,
            };
            //
            if (trip.passengerVal.limit && trip.passengerVal.limit < base.passengers.limit) {
                base.passengers.limit = trip.passengerVal.limit;
            }
            if (trip.vehicleVal.limit && trip.vehicleVal.limit < base.vehicles.limit) {
                base.vehicles.limit = trip.vehicleVal.limit;
            }
            if (trip.vehicleSoldOut) {
                base.vehicles.soldOut = true;
            }
            if (trip.tripValidation.vehicleRequired) {
                base.vehicles.required = true;
            }
            if (!trip.vehicle) {
                base.vehicles.allowed = false;
                base.vehicles.required = false;
            }

            if (trip.passengerVal.limit) {
                base.passengers.limit = Math.min(trip.passengerVal.limit, base.passengers.limit || 9);
            }

            //TRIP
            if (trip.tripValidation.vehicleEngineCategoryRequired) {
                base.trips.validation.vehicleEngineCategoryRequired = true;
            }
            if (trip.tripValidation.passengerLimit) {
                base.trips.validation.passengerLimit = Math.min(
                    trip.tripValidation.passengerLimit,
                    base.trips.validation.passengerLimit || 9,
                );
            }
            if (trip.tripValidation.petLimit) {
                base.trips.validation.petLimit = Math.min(
                    trip.tripValidation.petLimit,
                    base.trips.validation.petLimit || 2,
                );
            }
            if (trip.tripValidation.vehicleLimit) {
                base.trips.validation.vehicleLimit = Math.min(
                    trip.tripValidation.vehicleLimit,
                    base.trips.validation.vehicleLimit || 4,
                );
            }
            if (trip.tripValidation.disabledVehicles) {
                base.trips.validation.disabledVehicles = {
                    ...base.trips.validation.disabledVehicles,
                    ...trip.tripValidation.disabledVehicles,
                };
            }
        }
        return base;
    };

    STANDARD_FARE = 'standard_fare';
    static PAS_MIN_AGE_STATIC = 0;
    static PAS_MAX_AGE_STATIC = 99;
    PAS_MIN_AGE = BasketManagerSetup.PAS_MIN_AGE_STATIC;
    PAS_MAX_AGE = BasketManagerSetup.PAS_MAX_AGE_STATIC;

    private getPassengerCategories(selectedTrips: RouteTrip[]): ProcessedPassengerCategory[] {
        const ages = selectedTrips.flatMap((trip) =>
            trip.passengerCatAcc.flatMap((item) => [
                item.cat.minAge ? item.cat.minAge - 1 : this.PAS_MIN_AGE,
                item.cat.maxAge || this.PAS_MAX_AGE,
            ]),
        );

        ages.unshift(this.PAS_MIN_AGE);

        const filteredAges = ages.filter(onlyUnique).sort(sortSmallestFirst((num) => num));

        const ageRanges: ProcessedPassengerCategory[] = [];

        for (let index = 1; index < filteredAges.length; index++) {
            const minAge = filteredAges[index - 1] ? filteredAges[index - 1] + 1 : 0;
            const maxAge = filteredAges[index];
            ageRanges.push({
                minAge,
                maxAge,
                label: this.passengerGetAgeGroupLabelFromCat({ minAge, maxAge }),
            });
        }
        return ageRanges.reverse();
    }

    passengerGetAgeGroupLabel = (passengerData: RoutePricePassengerV2) => {
        const { basketDataProcessed } = this.getBmState();
        const cat = basketDataProcessed.passengers.categoryList.find(
            (cat) => cat.minAge <= passengerData.minAge && passengerData.maxAge <= cat.maxAge,
        );
        if (!cat) {
            throw createError({ code: 'CATEGORY_NOT_FOUND' });
        }
        return this.passengerGetAgeGroupLabelFromCat(cat);
    };

    passengerGetAgeGroupLabelFromCat = (data: { minAge: number; maxAge: number }) => {
        const allAges = data.maxAge >= this.PAS_MAX_AGE && data.minAge === 0;
        if (allAges) {
            return this.trns('app.passengerTicketSelection.allAges');
        }

        const ageText = this.passengerGetAgeGroupText(data, { withoutSuffix: true });

        if (data.minAge > 60) {
            return `${this.trns('app.basketManager.quote.senior')} (${ageText})`;
        }
        if (data.maxAge > 16) {
            return `${this.trns('app.basketManager.quote.adult')} (${ageText})`;
        }
        if (data.maxAge > 2) {
            return `${this.trns('app.basketManager.quote.child')} (${ageText})`;
        }
        return `${this.trns('app.basketManager.quote.infant')} (${ageText})`;
    };

    static passengerGetAgeGenericCategory = (data: { minAge: number; maxAge: number }): 'adult' | 'child' => {
        const allAges = data.maxAge >= BasketManagerSetup.PAS_MAX_AGE_STATIC && data.minAge === 0;
        if (allAges) {
            return 'adult';
        }
        if (data.maxAge > 16) {
            return 'adult';
        }
        if (data.maxAge > 2) {
            return 'child';
        }
        return 'child';
    };

    passengerGetAgeGenericCategory = (data: { minAge: number; maxAge: number }): 'adult' | 'child' => {
        return BasketManagerSetup.passengerGetAgeGenericCategory(data);
    };

    static getQueryBreakdownFromRoutePrice = (routePrice: RoutePriceV2): InitiateStateProps['query'] => {
        const quote: InitiateQuery = {
            adult: 0,
            child: 0,
            pet: routePrice.pets.length,
            //
            car: 0,
            trailer: 0,
            moto: 0,
            camper: 0,
            bus: 0,
            //
            bicycle: 0,
        };

        for (const passenger of routePrice.passengers) {
            const genericCategory = BasketManagerSetup.passengerGetAgeGenericCategory(passenger);
            switch (genericCategory) {
                case 'adult':
                    quote.adult++;
                    break;
                case 'child':
                    quote.child++;
                    break;
            }
        }

        for (const vehicle of routePrice.vehicles) {
            switch (vehicle.category) {
                case 'car':
                    quote.car++;
                    if (vehicle.trailerData) {
                        quote.trailer++;
                    }
                    break;
                case 'moto':
                case 'trike':
                case 'quad':
                    quote.moto++;
                    break;
                case 'bike':
                    quote.bicycle++;
                    break;
                case 'camper':
                    quote.camper++;
                    break;
                case 'bus':
                    quote.bus++;
                    break;
                default:
                    break;
            }
        }
        return quote;
    };

    getQueryBreakdownFromRoutePrice = (routePrice: RoutePriceV2): InitiateStateProps['query'] => {
        return BasketManagerSetup.getQueryBreakdownFromRoutePrice(routePrice);
    };

    getQueryBreakdown = (): InitiateStateProps['query'] => {
        const { routePrice } = this.getBmState();
        const quote: InitiateQuery = {
            adult: 0,
            child: 0,
            pet: routePrice.pets.length,
            //
            car: 0,
            trailer: 0,
            moto: 0,
            camper: 0,
            bus: 0,
            //
            bicycle: 0,
        };

        for (const passenger of routePrice.passengers) {
            const genericCategory = this.passengerGetAgeGenericCategory(passenger);
            switch (genericCategory) {
                case 'adult':
                    quote.adult++;
                    break;
                case 'child':
                    quote.child++;
                    break;
            }
        }

        for (const vehicle of routePrice.vehicles) {
            switch (vehicle.category) {
                case 'car':
                    quote.car++;
                    if (vehicle.trailerData) {
                        quote.trailer++;
                    }
                    break;
                case 'moto':
                case 'trike':
                case 'quad':
                    quote.moto++;
                    break;
                case 'bike':
                    quote.bicycle++;
                    break;
                case 'camper':
                    quote.camper++;
                    break;
                case 'bus':
                    quote.bus++;
                    break;
                default:
                    break;
            }
        }
        return quote;
    };

    private passengerGetAgeGroupText = (
        data: {
            minAge: number;
            maxAge: number;
        },
        options?: {
            withoutSuffix?: boolean;
        },
    ) => {
        if (data.minAge === data.maxAge) {
            return this.trns('app.passengerTicketSelection.yearsOld', {
                age: data.minAge,
            });
        }
        if (data.maxAge === this.PAS_MAX_AGE || data.maxAge === 99) {
            if (options?.withoutSuffix) {
                return data.minAge + '+';
            }
            return this.trns('app.passengerTicketSelection.yearsOld', {
                age: data.minAge + '+',
            });
        }
        if (options?.withoutSuffix) {
            return `${data.minAge} - ${data.maxAge}`;
        }
        return this.trns('app.passengerTicketSelection.yearsOldRange', {
            minAge: data.minAge,
            maxAge: data.maxAge,
        });
    };

    //Pets
    private getPetCatAccDic = (trip: RouteTrip) => {
        const cache: KeyVal<RouteTripDictionaryClient['petCatAccDic'][0]> = {};
        if (!trip.petCatAcc) {
            return {};
        }
        for (const catAcc of trip.petCatAcc) {
            const accommodations = catAcc.accommodations?.length ? catAcc.accommodations : trip.petAcc;
            cache[catAcc.cat] = {
                ...catAcc,
                accommodations,
                accommodationsDic: mapArrayToKeyValue(accommodations, (acc) => acc.code),
            };
        }

        return cache;
    };

    //Vehicles
    private getVehicleCatAccDic = (trip: RouteTrip) => {
        const cache: KeyVal<RouteTripDictionaryClient['vehicleCatAccDic'][0]> = {};
        if (!trip.vehicleCatAcc) {
            return {};
        }
        for (const catAcc of trip.vehicleCatAcc) {
            const accommodations = catAcc.accommodations;
            cache[catAcc.category.categoryGroup] = {
                ...catAcc,
                discountsDic: {},
                discounts: [],
                accommodations,
                accommodationsDic: mapArrayToKeyValue(accommodations, (acc) => acc.code),
            };
        }
        const motoKey: TicketVehicleMotoCategory = 'moto';
        const trikeKey: TicketVehicleMotoCategory = 'trike';
        if (!cache[trikeKey]) {
            cache[trikeKey] = cache[motoKey];
        }

        const quadKey: TicketVehicleMotoCategory = 'quad';
        if (!cache[quadKey]) {
            cache[quadKey] = cache[motoKey];
        }

        return cache;
    };

    //---------------ROUTE_PRICE---------------//
    private generateRoutePrice = (selectedTrips: RouteTrip[]): RoutePriceV2 => {
        return {
            v: '2',
            passengers: [],
            vehicles: [],
            pets: [],
            total: undefined,
            trips: selectedTrips.map((trip) => ({
                key: trip.key,
                //
                tariffReq: this.getTariffReq(trip),
                //
                passengerAccReq: [],
                vehicleAccReq: [],
                petAccReq: [],
                //
                cabinAccGroupReq: [],
                //
                accPrices: {},
                totalPrice: 0,
            })),
            passengerExtraValidation: {},
            vehicleExtraValidation: {},
            ccy: selectedTrips[0].ccy,
        };
    };

    private getTariffReq = (trip: RouteTrip): TripPriceTariffAccommodation | undefined => {
        if (!trip.tripTariffs || !trip.tripTariffs.length) {
            return undefined;
        }
        const firstTariff = trip.tripTariffs[0];
        return {
            tariffId: generateRandomString(),
            tariffCode: firstTariff.tariffCode,
        };
    };

    generateClientState = ({ selectedTrips, query }: InitiateStateProps): ClientBasketState => {
        const bmState = this.getBmState();
        const canAddVehicle = !!(
            bmState.basketDataProcessed.vehicles.allowed &&
            bmState.basketDataProcessed.vehicles.limit > bmState.routePrice.vehicles.length
        );
        return {
            quoteOptions: {
                passengers: {
                    limit: bmState.basketDataProcessed.passengers.limit || 0,
                    canAddPassenger:
                        bmState.routePrice.passengers.length < bmState.basketDataProcessed.passengers.limit,
                    canRemovePassenger: false,
                    list: [],
                },
                pets: {
                    limit: bmState.basketDataProcessed.pets.limit || 0,
                    canAddPet: false,
                    canRemovePet: true,
                    list: [],
                    unselectedList: [...bmState.unselectedPets],
                },
                vehicles: {
                    limit: bmState.basketDataProcessed.vehicles.limit || 0,
                    canAddVehicle,
                    canRemoveVehicle: !bmState.basketDataProcessed.vehicles.required,
                    vehiclesAllowed: bmState.basketDataProcessed.vehicles.allowed,
                    vehiclesMandatory: bmState.basketDataProcessed.vehicles.required,
                    vehiclesSoldOut: bmState.basketDataProcessed.vehicles.soldOut,
                    list: [],
                    unselectedList: [...bmState.unselectedVehicles],
                },
                query,
                validation: {
                    continue: false,
                },
            },
            pricing: {
                trips: selectedTrips.map((trip) => ({
                    tripKey: trip.key,
                    tariff: undefined,
                    passengers: [],
                    passengersGroup: undefined,
                    groupPassengers: false,
                    skipAccommodationSelection: !!(
                        trip.routeConfig?.config?.skipTripPricingIfNoDetails && trip.passengerAcc.length === 1
                    ),
                    vehicles: [],
                    hideVehicles: bmState.basketDataProcessed.flowConfig.hideVehicleDetails,
                    pets: [],
                    validation: {},
                    value: undefined,
                })),
                validation: {
                    status: 'verify',
                },
                ccy: this.getCcy(selectedTrips),
                value: undefined,
            },
            details: {
                showQuoteOptions: false,
                passengers: [],
                pets: [],
                vehicles: [],
                validation: {
                    continue: false,
                },
            },
            flowConfig: {
                skipPricingPage: bmState.basketDataProcessed.flowConfig.skipPricingPage,
                skipTripPricingDetails: bmState.basketDataProcessed.flowConfig.skipTripPricingDetails,
                displayAccommodationText: bmState.basketDataProcessed.flowConfig.displayAccommodationText,
                showAccAsUpgrade: bmState.basketDataProcessed.flowConfig.showAccAsUpgrade,
            },
        };
    };

    private getCcy = (selectedTrips: RouteTrip[]): ClientBasketState['pricing']['ccy'] => {
        const ccyList = selectedTrips.map((item) => item.ccy).filter(onlyUnique);
        if (ccyList.length !== 1) {
            throw createError({ code: 'MORE_THAN_ONE_CCY_DETECTED' });
        }
        return {
            symbol: selectedTrips[0].ccySymbol,
            value: selectedTrips[0].ccy,
        };
    };

    private getFilteredAndDefaultAcc = (
        trip: RouteTrip,
        catAccommodations: RoutePassengerAccommodationDictionaryClient[],
        numberOfPassengers: number,
    ) => {
        const filteredAcc = catAccommodations
            .filter((acc) => {
                if (acc.type !== 'cabin') {
                    return true;
                }
                if (!acc.wholeUse) {
                    return true;
                }
                if (acc.minCapacity) {
                    return acc.minCapacity <= numberOfPassengers;
                }
                const enoughPassengersToFillWholeCapacity = acc.capacity <= numberOfPassengers;
                return enoughPassengersToFillWholeCapacity;
            })
            .sort(sortSmallestFirst((acc) => acc.price || 10000));

        const defaultAcc =
            trip.passengerDefaultAcc ||
            filteredAcc.find((item) => !item.nonRefundable && !item.nonAmendable) ||
            filteredAcc[0];

        return { filteredAcc, defaultAcc };
    };

    //
    getTripDictionary = (tripKey: string): RouteTripDictionaryClient => {
        const dic = this.getBmState().tripDictionaries[tripKey];
        if (!dic) {
            throw createError({ code: 'DICTIONARY_NOT_FOUND' });
        }
        return dic;
    };

    refreshDictionaries = () => {
        const bmState = this.getBmState();

        const tripDictionaries = mapArrayToKeyValue(
            bmState.selectedTrips,
            (item) => item.key,
            (item, index) => this.mapTripDictionary(item, index, bmState.routePrice.passengers.length || 1),
        );

        const basketDataProcessed = this.getBasketDataProcessed(bmState.selectedTrips, tripDictionaries);

        this.bmState = {
            ...bmState,
            basketDataProcessed,
            tripDictionaries,
        };
    };

    skipSelectiveUpdate = (selectiveUpdate: SelectiveUpdate | undefined, key: keyof SelectiveUpdate) => {
        if (selectiveUpdate && !selectiveUpdate[key]) {
            return true;
        }
        return false;
    };

    //
    validateState = (state: ClientBasketState) => {
        const detailsValid = this.validateDetails(state);
        state.details.validation.continue = detailsValid;
    };

    private validateDetails = (state: ClientBasketState) => {
        const { details } = state;
        if (details.passengers.length === 0 || !details.passengers.every((pas) => pas.details.complete)) {
            return false;
        }
        if (details.passengers.length === 0 || !details.vehicles.every((veh) => veh.details.complete)) {
            return false;
        }
        if (!details.pets.every((pey) => pey.details.complete)) {
            return false;
        }
        return true;
    };

    //---------------CAR_CALL_BACKS---------------//
    getCarMakeList = async (): Promise<VehicleClientGetMakesResponse> => {
        try {
            if (this.OnCarMakeList) {
                return this.OnCarMakeList();
            }
        } catch {}
        return {
            list: [],
        };
    };
    getCarModelsList = async (makeId: string): Promise<VehicleClientGetModelsResponse> => {
        try {
            if (this.OnCarModelList) {
                return this.OnCarModelList(makeId);
            }
        } catch {}
        return {
            list: [],
        };
    };
    getCarModel = async (modelId: string): Promise<VehicleClientGetModelResponse | undefined> => {
        try {
            if (this.OnCarModelId) {
                const res = await this.OnCarModelId(modelId);
                this.setVehicleModelToCache('car', modelId, res.vehicle);
                return res;
            }
        } catch {}
        return undefined;
    };

    //---------------MOTO_CALL_BACKS---------------//
    getMotoMakeList = async (): Promise<VehicleClientGetMakesResponse> => {
        try {
            if (this.OnMotoMakeList) {
                return this.OnMotoMakeList();
            }
        } catch {}
        return {
            list: [],
        };
    };
    getMotoModelsList = async (makeId: string): Promise<VehicleClientGetModelsResponse> => {
        try {
            if (this.OnMotoModelList) {
                return this.OnMotoModelList(makeId);
            }
        } catch {}
        return {
            list: [],
        };
    };
    getMotoModel = async (modelId: string): Promise<VehicleClientGetModelResponse | undefined> => {
        try {
            if (this.OnMotoModelId) {
                const res = await this.OnMotoModelId(modelId);
                this.setVehicleModelToCache('moto', modelId, res.vehicle);
                return res;
            }
        } catch {}
        return undefined;
    };

    private vehicleModelCache: KeyVal<KeyVal<VehicleModelClient>> = {};

    getVehicleModelFromCache = (category: TicketVehicleCategory, id: string): VehicleModelClient | undefined => {
        switch (category) {
            case 'car':
                return this.vehicleModelCache[category]?.[id];
            case 'moto':
                return this.vehicleModelCache[category]?.[id];
            default:
                break;
        }
    };

    setVehicleModelToCache = (category: 'car' | 'moto', id: string, model: VehicleModelClient) => {
        if (!this.vehicleModelCache[category]) {
            this.vehicleModelCache[category] = {};
        }
        this.vehicleModelCache[category][id] = model;
    };

    refreshVehicleModelCache = async () => {
        const { routePrice } = this.getBmState();
        for (const vehicle of routePrice.vehicles) {
            if (!vehicle.model) {
                continue;
            }
            try {
                switch (vehicle.category) {
                    case 'car':
                        if (this.getVehicleModelFromCache('car', vehicle.model.id)) {
                            break;
                        }
                        await this.getCarModel(vehicle.model.id);
                        break;
                    case 'moto':
                        if (this.getVehicleModelFromCache('moto', vehicle.model.id)) {
                            break;
                        }
                        await this.getMotoModel(vehicle.model.id);
                        break;
                    default:
                        break;
                }
            } catch {
                continue;
            }
        }
    };
}
