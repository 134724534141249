import { translate } from '../lang';
import { ErrorObject } from './error.utils';

export const getFirebaseError = (error: any): ErrorObject => {
  switch (error?.code) {
    case 'auth/weak-password': {
      return {
        code: error.code,
        message: error?.getReason?.() || translate('app.feedback.inputs.weakPassword'),
        type: 'firebase',
      };
    }
    case 'auth/email-already-in-use': {
      return {
        code: error.code,
        message: translate('app.feedback.inputs.emailInUse'),
        type: 'firebase',
      };
    }

    case 'auth/invalid-email': {
      return {
        code: error.code,
        message: translate('app.signIn.invalidEmail'),
        type: 'firebase',
      };
    }
    case 'auth/user-not-found': {
      return {
        code: error.code,
        message: translate('app.signIn.invalidUser'),
        type: 'firebase',
      };
    }

    case 'auth/user-disabled': {
      return {
        code: error.code,
        message: translate('app.signIn.disable'),
        type: 'firebase',
      };
    }

    case 'auth/wrong-password': {
      return {
        code: error.code,
        message: translate('app.signIn.invalidPassword'),
        type: 'firebase',
      };
    }

    case 'auth/invalid-verification-code': {
      return {
        code: error.code,
        message: translate('app.signIn.invalidVerificationCode'),
        type: 'firebase',
      };
    }
    case 'auth/invalid-verification-id':
    case 'auth/credential-already-in-use':
    case 'auth/invalid-credential':
    case 'auth/provider-already-linked': {
      return {
        code: error.code,
        message: translate('app.signIn.errorLogSupport'),
        type: 'firebase',
      };
    }

    default: {
      return error;
    }
  }
};
