import {
    KeyVal,
    PriceClientVerifyLoyaltyRequest,
    PriceClientVerifyLoyaltyResponseV2,
    PriceClientVerifyRoutePriceResponseV2,
    RoutePriceV2,
} from '@naus-code/naus-client-types';
import { BasketManagerState } from '../types/managerState/managerState';
import { ClientBasketState } from '../types/clientState';
import { generateRandomString } from '../utils/functions';
import { createError } from '../errors';

interface DevOptions {
    disablePriceFetch?: boolean;
    mockPriceFetch?: boolean;
    disableAutoPriceFetch?: boolean;
    enableLogs?: boolean;
    loadTestTranslations?: boolean;
}
export interface BasketManagerConfig {
    DATE_FORMAT_CLIENT: string;
    DATE_FORMAT_SERVER: string;
}

export interface BasketManagerOptions {
    dispatchManager: (state: BasketManagerState) => void;
    dispatchBasketAction: () => void;
    //
    config: BasketManagerConfig;
    devOptions?: DevOptions;
    // onBasketError?: (error: BasketManagerErrorRes) => void;
    //
    translate?: (key: BasketManagerTrnsKeys, replace?: KeyVal<string | number>) => string;
    logError?: (error: any) => void;
    //
    //Server endpoints
    onVerifyPrice?: (routePrice: RoutePriceV2) => Promise<PriceClientVerifyRoutePriceResponseV2>;
    onVerifyLoyalty?: (options: PriceClientVerifyLoyaltyRequest) => Promise<PriceClientVerifyLoyaltyResponseV2>;
}

export class BasketMangerBase {
    private dispatchBasketAction: () => void;
    // managerState: BasketManagerState;
    // private verifyAttention?: PriceClientVerifyAttention;
    translate;
    private translations;
    devOptions?;
    config;
    onVerifyPrice;
    onVerifyLoyalty;

    private _v = `BASKET_MANAGER[${generateRandomString()}]`;

    _log = (title: string, ...logs: any) => {
        if (!this.devOptions?.enableLogs) {
            return;
        }
        console.log(`${this._v}: ${title}`);
        const logArr = [...logs];
        if (logArr.length) {
            console.log(...logs);
        }
    };

    constructor(props: BasketManagerOptions) {
        this.dispatchBasketAction = props.dispatchBasketAction;
        this.config = props.config;
        this.devOptions = props.devOptions;
        this.translate = props.translate;
        this.onVerifyPrice = props.onVerifyPrice;
        this.onVerifyLoyalty = props.onVerifyLoyalty;
        if (props.devOptions?.loadTestTranslations) {
            this.translations = translations;
        }
    }

    verifyPrice: Required<BasketManagerOptions>['onVerifyPrice'] = async (props) => {
        if (!this.onVerifyPrice) {
            throw createError({ code: 'BM_CALLBACK_NOT_INITIALIZED' });
        }
        return this.onVerifyPrice(props);
    };

    verifyLoyalty: Required<BasketManagerOptions>['onVerifyLoyalty'] = async (props) => {
        if (!this.onVerifyLoyalty) {
            throw createError({ code: 'BM_CALLBACK_NOT_INITIALIZED' });
        }
        return this.onVerifyLoyalty(props);
    };

    private _tempAction: (state: ClientBasketState) => void = () => null;

    dispatchBasket = (action: (state: ClientBasketState) => void) => {
        this._log('DISPATCH-STATE');
        this._tempAction = action;
        this.dispatchBasketAction();
    };

    dispatchBasketWrapper = (state: ClientBasketState | undefined, action: (state: ClientBasketState) => void) => {
        if (state) {
            action(state);
            return;
        }
        this.dispatchBasket(action);
    };

    setBasketStateListener = (state: ClientBasketState) => {
        this._tempAction(state);
        this._tempAction = () => null;
    };

    trns = (key: BasketManagerTrnsKeys, replace?: KeyVal<string | number>) => {
        if (this.translate) {
            return this.translate(key, replace);
        }
        if (!this.translations) {
            return key;
        }
        return replaceHandlebars(translations[key], replace || {});
    };
}

function replaceHandlebars(text: string, replacements: KeyVal<string | number>) {
    const pattern = /{{([^{}]+)}}/g;
    return text.replace(pattern, (_match, name) => replacements[name] + '' || '');
}

export type BasketManagerTrnsKeys = keyof typeof translations;

const translations = {
    'app.common.select': 'Select',
    'app.passengerTicketSelection.passenger': 'Passenger {{count}}',
    'app.passengerTicketSelection.pet': 'Pet {{count}}',
    'app.basketManager.quote.eligibleDriverRequired': 'Driver over {{age}} required',
    'app.basketManager.quote.senior': 'Senior',
    'app.basketManager.quote.adult': 'Adult',
    'app.basketManager.quote.child': 'Child',
    'app.basketManager.quote.infant': 'Infant',
    //
    'app.basketManager.quote.diesel': 'Diesel',
    'app.basketManager.quote.petrol': 'Petrol',
    'app.basketManager.quote.hybrid': 'Hybrid',
    'app.basketManager.quote.plug-in': 'Plug-in',
    'app.basketManager.quote.gas': 'Gas',
    //
    'app.passengerTicketSelection.yearsOld': '',
    'app.passengerTicketSelection.allAges': '',
    'app.passengerTicketSelection.yearsOldRange': '',
    //
    'app.basketManager.firstName': 'First name',
    'app.basketManager.nickName': 'Nickname',
    'app.basketManager.name': 'Name',
    'app.basketManager.lastName': 'Last name',
    'app.basketManager.biologicalSex': 'Gender',
    'app.passengerTicketSelection.biologicalSexInfo': '',
    'app.basketManager.male': 'Male',
    'app.basketManager.female': 'Female',
    'app.basketManager.nationality': 'Nationality',
    'app.basketManager.selectCountry': 'Select your country',
    'app.basketManager.weight': 'Weight',
    'app.basketManager.placeOfBirth': 'Place of birth',
    'app.basketManager.birthDate': 'Birth date',
    'app.basketManager.documentType': 'Passport or ID',
    'app.basketManager.documentNumber': 'Document number',
    'app.basketManager.documentExpiryDate': 'Expiry date',
    'app.basketManager.greekResidentId': 'Greek resident ID (M.A.N)',
    'app.basketManager.specialNeeds': 'Special needs',
    'app.basketManager.passport': 'Passport',
    //NEW
    'app.basketManager.residentBalearic': '',
    'app.basketManager.residentCanary': '',
    'app.basketManager.residentCeuta': '',
    'app.basketManager.residentFormentera': '',
    'app.basketManager.residentMelilla': '',
    'app.basketManager.residentSardinia': '',
    'app.basketManager.residentSicily': '',
    'app.basketManager.residentIschia': '',
    'app.basketManager.residentCorsica': '',
    'app.basketManager.id': 'Identity document',
    'app.basketManager.medId': 'Medical document',
    'app.basketManager.accompanyPassenger': 'Medical document',
    //
    'app.error.notFound': 'Not found',
    'app.error.incorrectName': 'Incorrect first name',
    'app.error.incorrectLastName': 'Incorrect last name',
    'app.error.incorrectBirthDate': 'Incorrect birth date',
    'app.error.incorrectNationality': 'Incorrect nationality',
    'app.error.incorrectSex': 'Incorrect sex',
    //

    //

    'app.tripDetails.pet': 'Pet',
    'app.basketManager.dog': 'Dog',
    'app.basketManager.cat': 'Cat',
    'app.basketManager.guideDog': 'Guide dog',
    //
    'app.ticketSelection.standard': 'Standard',
    //
    'app.passengerTicketSelection.other': 'Other',
    'app.passengerTicketSelection.car': 'Car',
    'app.passengerTicketSelection.moto': 'Motorcycle',
    'app.passengerTicketSelection.bike': 'Bicycle',
    'app.passengerTicketSelection.trike': 'Trike',
    'app.passengerTicketSelection.quad': 'Quad',
    'app.passengerTicketSelection.camper': 'Camper',
    'app.passengerTicketSelection.trailer': 'Trailer',
    'app.passengerTicketSelection.bus': 'Bus',
    'app.passengerTicketSelection.vehicle': 'Vehicle {{count}}',

    //
    'app.selectVehicle.height': 'Height',
    'app.selectVehicle.length': 'Length',
    'app.basketManager.plateNumber': 'Plate number',
};
