import { AxiosError } from 'axios';
import { translate } from '../lang';
import { ErrorObject, isCodeMessageObject } from './error.utils';

function isOnline() {
  if (navigator.onLine) {
    return true;
  }
  return false;
}

export type AxiosErrorCodes =
  | 'ECONNABORTED'
  | 'EHOSTUNREACH'
  | 'ERR_NETWORK'
  | 'ENOTFOUND'
  | 'ECONNREFUSED'
  | 'EAI_AGAIN'
  | 'ECONNRESET'
  | 'UNKNOWN';

export const getAxiosUserError = (error: AxiosError<unknown, any>): ErrorObject => {
  /**
   * Case 1: If the response contains a `{ code, message }` structure, return it directly.
   */
  if (error?.response?.data && isCodeMessageObject(error.response.data)) {
    return { ...error.response.data, type: 'server-response' };
  }

  /**
   * Case 2: Request was cancelled (either by abort signal or by browser)
   */
  if(error.code === 'ERR_CANCELED') {
    return {
      code: error.code,
      message:translate('app.feedback.error.interrupted'),
      type: 'axios'
    }
  }

  /**
   * Case 3: No response received (Network issues, server downtime, or CORS errors).
   */
  if (error.request && !error.response) {
    return {
      code: 'NO_RESPONSE',
      message: translate('app.feedback.error.serverUnreachable'),
      type: 'axios',
    };
  }

  /**
   * Case 4: Specific Axios error codes.
   */
  switch (error?.code) {
    // Group 1: Timeout and Unreachable Server
    case 'ECONNABORTED':
    case 'EHOSTUNREACH':
      // 'The request is taking longer than expected. Please check your connection or try again shortly.';
      return {
        code: error.code,
        message: translate('app.feedback.error.timeout'),
        type: 'axios',
      };
    // Group 2: Network Issues (Offline or Server Unreachable)
    case 'ERR_NETWORK':
      // Call isOnline to retry for a few seconds
      const online = isOnline();
      if (!online) {
        return {
          code: 'OFFLINE',
          message: translate('app.feedback.error.offline'),
          type: 'axios',
        };
      }
      // 'We're having trouble connecting to the server. Please try again later.';
      return {
        code: error.code,
        message: translate('app.feedback.error.serverUnreachable'),
        type: 'axios',
      };

    // Group 3: Server or Service Unavailability
    case 'ENOTFOUND':
    case 'ECONNREFUSED':
    case 'EAI_AGAIN':
      // userMessage = 'The service is temporarily unavailable. Please try again later.';
      // console.error('Service Unavailable: DNS or connection issue.');
      return {
        code: error.code,
        message: translate('app.feedback.error.serviceUnavailable'),
        type: 'axios',
      };

    // Group 4: Connection Issues During Request
    case 'ECONNRESET':
      // userMessage = 'The connection was interrupted. Please try again.';
      // console.error('Connection Reset.');
      return {
        code: 'OFFLINE',
        message: translate('app.feedback.error.offline'),
        type: 'axios',
      };
    // Default Catch-all
    default: {
      if (error.message === 'Network Error') {
        // userMessage =
        //   'We're experiencing connectivity issues. Please refresh the page or check your connection.';
        // console.error('General Network Error.');
        return {
          code: 'AXIOS_UNKNOWN',
          message: translate('app.feedback.error.interrupted'),
          type: 'axios',
        };
      }
      // userMessage = 'An unexpected error occurred. Please try again later.';
      // console.error('Unexpected Error:', error.message);
      return {
        code: 'AXIOS_UNKNOWN',
        message: translate('app.feedback.error.unexpectedError'),
        type: 'axios',
      };
    }
  }
};
