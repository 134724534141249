import i18next, { TOptions } from 'i18next';
import { useMemo } from 'react';
import { useTranslation as useBaseTranslate } from 'react-i18next';
import type { TKeys as TKeysBase } from './__builder/locale.types';

export type TKeys = TKeysBase;
export const translate = (
  key: TKeys,
  options?: { [key: string]: string | number | undefined },
) => {
  return i18next.t(key, { ...options, interpolation: { escapeValue: false } });
};

export const useTranslate = () => {
  const { t } = useBaseTranslate();
  return useMemo(
    () =>
      <TInterpolationMap extends object = { [key: string]: any }>(
        key: TKeys,
        options?: TOptions<TInterpolationMap>,
      ) =>
        t(key, { ...options, interpolation: { escapeValue: false } }),
    [t],
  );
};
